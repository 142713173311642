export const environment = {
  production: true,
  apiurl: 'api/',
  authurl: 'api/',
  chatHub: 'api/chat',
  adminChatHub: 'api/adminchat',
  videoHub: 'https://jitsi.dotenterprise.com',
  videoHubDomain: 'jitsi.dotenterprise.com',
  videoAuthDomain: 'https://virtualexpo.info',
  API_BASE_PATH: '.',
  versionCheckURL : '/version.json',
  cdnURL: 'https://cdn.virtualexpo.info',
  imageUploadSize: 5242880,
  fileUploadSize: 33554432,
  watchdogInterval: 120000,
  sentryEnabled: true,
  sentryDsn: 'https://76b0690649b84d8e93ea9d53424fff4d@debug.nethotline.io/2',
  agoraId: '7619ac48cb274b109606888a71d9a8a0',
  enableOneOnOne: true,
  enableSessions: false,
  timeStamp: '2023-11-12 13:26:50 UTC',
  webexIntegrationPartnerId: 'df022c4e-d85e-4594-a161-03247f5465fd',
  betaServer: 'beta.virtualexpo.info'
};
