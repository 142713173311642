/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { CategoryDto } from './categoryDto';
import { ExhibitorDto } from './exhibitorDto';
import { InventoryItemType } from './inventoryItemType';
import { VideoChatMode } from './videoChatMode';
import { ExpoDesignDto } from './expoDesignDto';
import { AccessMode } from './accessMode';
import { ExpoContactDto } from './expoContactDto';
import { ExhibitorGroupDto } from './exhibitorGroupDto';


export interface ExpoDto { 
    id?: string;
    shortKey?: string;
    name: string;
    description?: string;
    descriptionLong?: string;
    intro?: string;
    logo?: string;
    logoSquare?: string;
    logoWide?: string;
    dateStart: Date;
    dateEnd: Date;
    defaultScene?: number;
    active?: boolean;
    isPrivate?: boolean;
    ownerId?: string;
    ownerName?: string;
    exhibitors?: Array<ExhibitorDto>;
    categories?: Array<CategoryDto>;
    contacts?: Array<ExpoContactDto>;
    exhibitorGroups?: Array<ExhibitorGroupDto>;
    groupingEnabled?: boolean;
    chatActive?: boolean;
    schedulingActive?: boolean;
    worldActive?: boolean;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    showHall?: boolean;
    accessMode?: AccessMode;
    defaultLanguage?: string;
    sizes?: string;
    displayMax?: number;
    linkMax?: number;
    infoSlideMax?: number;
    textChatEnabled?: boolean;
    videoChatEnabled?: boolean;
    calendarEnabled?: boolean;
    stageEnabled?: boolean;
    stageCount?: number;
    boothCount?: number;
    isPreview?: boolean;
    domain?: string;
    timeZone?: string;
    introType?: InventoryItemType;
    introSource?: string;
    expoConsultant?: string;
    videoChatMode?: VideoChatMode;
    vodLibraryType?: InventoryItemType;
    vodLibraryId?: string;
    randomChatNames?: boolean;
    exhibitorInfoVisibility?: boolean;
    liveStreamAvailable?: boolean;
    liveStreamEnabled?: boolean;
    tagsAvailable?: boolean;
    tagsEnabled?: boolean;
    tagsExhibitorEditable?: boolean;
    tags?: Array<string>;
    catalogEnabled?: boolean;
    boothPresentationEnabled?: boolean;
    surveyEnabled?: boolean;
    zoomRequiresLogin?: boolean;
    externalLinksDirectOpen?: boolean;
    mediaLinksSkipZoom?: boolean;
    rowVersion?: number;
    expoDesign?: ExpoDesignDto;
}

/**
 * Namespace for property- and property-value-enumerations of ExpoDto.
 */
export namespace ExpoDto {
    /**
     * All properties of ExpoDto.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        intro = 'intro',
        logo = 'logo',
        logoSquare = 'logoSquare',
        logoWide = 'logoWide',
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        defaultScene = 'defaultScene',
        active = 'active',
        isPrivate = 'isPrivate',
        ownerId = 'ownerId',
        ownerName = 'ownerName',
        exhibitors = 'exhibitors',
        categories = 'categories',
        contacts = 'contacts',
        exhibitorGroups = 'exhibitorGroups',
        groupingEnabled = 'groupingEnabled',
        chatActive = 'chatActive',
        schedulingActive = 'schedulingActive',
        worldActive = 'worldActive',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        showHall = 'showHall',
        accessMode = 'accessMode',
        defaultLanguage = 'defaultLanguage',
        sizes = 'sizes',
        displayMax = 'displayMax',
        linkMax = 'linkMax',
        infoSlideMax = 'infoSlideMax',
        textChatEnabled = 'textChatEnabled',
        videoChatEnabled = 'videoChatEnabled',
        calendarEnabled = 'calendarEnabled',
        stageEnabled = 'stageEnabled',
        stageCount = 'stageCount',
        boothCount = 'boothCount',
        isPreview = 'isPreview',
        domain = 'domain',
        timeZone = 'timeZone',
        introType = 'introType',
        introSource = 'introSource',
        expoConsultant = 'expoConsultant',
        videoChatMode = 'videoChatMode',
        vodLibraryType = 'vodLibraryType',
        vodLibraryId = 'vodLibraryId',
        randomChatNames = 'randomChatNames',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        liveStreamAvailable = 'liveStreamAvailable',
        liveStreamEnabled = 'liveStreamEnabled',
        tagsAvailable = 'tagsAvailable',
        tagsEnabled = 'tagsEnabled',
        tagsExhibitorEditable = 'tagsExhibitorEditable',
        tags = 'tags',
        catalogEnabled = 'catalogEnabled',
        boothPresentationEnabled = 'boothPresentationEnabled',
        surveyEnabled = 'surveyEnabled',
        zoomRequiresLogin = 'zoomRequiresLogin',
        externalLinksDirectOpen = 'externalLinksDirectOpen',
        mediaLinksSkipZoom = 'mediaLinksSkipZoom',
        rowVersion = 'rowVersion',
        expoDesign = 'expoDesign'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of ExpoDto.
    */
    export const ModelValidators: {[K in keyof ExpoDto]: [string, ValidatorFn][]} = {
        id: [
        ],
        shortKey: [
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(6)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        descriptionLong: [
        ],
        intro: [
        ],
        logo: [
        ],
        logoSquare: [
        ],
        logoWide: [
        ],
        dateStart: [
                ['required', Validators.required],
        ],
        dateEnd: [
                ['required', Validators.required],
        ],
        defaultScene: [
        ],
        active: [
        ],
        isPrivate: [
        ],
        ownerId: [
        ],
        ownerName: [
        ],
        exhibitors: [
        ],
        categories: [
        ],
        contacts: [
        ],
        exhibitorGroups: [
        ],
        groupingEnabled: [
        ],
        chatActive: [
        ],
        schedulingActive: [
        ],
        worldActive: [
        ],
        googleAnalyticsId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        showHall: [
        ],
        accessMode: [
        ],
        defaultLanguage: [
        ],
        sizes: [
        ],
        displayMax: [
        ],
        linkMax: [
        ],
        infoSlideMax: [
        ],
        textChatEnabled: [
        ],
        videoChatEnabled: [
        ],
        calendarEnabled: [
        ],
        stageEnabled: [
        ],
        stageCount: [
        ],
        boothCount: [
        ],
        isPreview: [
        ],
        domain: [
        ],
        timeZone: [
        ],
        introType: [
        ],
        introSource: [
        ],
        expoConsultant: [
        ],
        videoChatMode: [
        ],
        vodLibraryType: [
        ],
        vodLibraryId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        randomChatNames: [
        ],
        exhibitorInfoVisibility: [
        ],
        liveStreamAvailable: [
        ],
        liveStreamEnabled: [
        ],
        tagsAvailable: [
        ],
        tagsEnabled: [
        ],
        tagsExhibitorEditable: [
        ],
        tags: [
        ],
        catalogEnabled: [
        ],
        boothPresentationEnabled: [
        ],
        surveyEnabled: [
        ],
        zoomRequiresLogin: [
        ],
        externalLinksDirectOpen: [
        ],
        mediaLinksSkipZoom: [
        ],
        rowVersion: [
        ],
        expoDesign: [
        ],
    };

    /**
    * The FormControlFactory for ExpoDto.
    */
    export class FormControlFactory extends BaseFormControlFactory<ExpoDto> {

        /**
         * Constructor.
         *
         * @param model An existing model for ExpoDto.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: ExpoDto = {
            id: null,
            shortKey: null,
            name: null,
            description: null,
            descriptionLong: null,
            intro: null,
            logo: null,
            logoSquare: null,
            logoWide: null,
            dateStart: null,
            dateEnd: null,
            defaultScene: null,
            active: null,
            isPrivate: null,
            ownerId: null,
            ownerName: null,
            exhibitors: null,
            categories: null,
            contacts: null,
            exhibitorGroups: null,
            groupingEnabled: null,
            chatActive: null,
            schedulingActive: null,
            worldActive: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            showHall: null,
            accessMode: null,
            defaultLanguage: null,
            sizes: null,
            displayMax: null,
            linkMax: null,
            infoSlideMax: null,
            textChatEnabled: null,
            videoChatEnabled: null,
            calendarEnabled: null,
            stageEnabled: null,
            stageCount: null,
            boothCount: null,
            isPreview: null,
            domain: null,
            timeZone: null,
            introType: null,
            introSource: null,
            expoConsultant: null,
            videoChatMode: null,
            vodLibraryType: null,
            vodLibraryId: null,
            randomChatNames: null,
            exhibitorInfoVisibility: null,
            liveStreamAvailable: null,
            liveStreamEnabled: null,
            tagsAvailable: null,
            tagsEnabled: null,
            tagsExhibitorEditable: null,
            tags: null,
            catalogEnabled: null,
            boothPresentationEnabled: null,
            surveyEnabled: null,
            zoomRequiresLogin: null,
            externalLinksDirectOpen: null,
            mediaLinksSkipZoom: null,
            rowVersion: null,
            expoDesign: null,
          }
        ) {
            super(model, ExpoDto.ModelValidators);
        }
    }

}


