/*
 * VirtualExpo
 * All rights reserved.
 *
 * VirtualExpo
 * VirtualExpo API
 *
 * OpenAPI spec version: 3.0.1
 * 
 *
 * NOTE: This class is auto generated
 * Do not edit the class manually.
 */

import { ValidatorFn, Validators } from '@angular/forms';
import { BaseFormControlFactory } from '../baseformcontrol.factory';

import { ApplicationUser } from './applicationUser';
import { Category } from './category';
import { InventoryItemType } from './inventoryItemType';
import { SidebarMode } from './sidebarMode';
import { VideoChatMode } from './videoChatMode';
import { Stage } from './stage';
import { AccessMode } from './accessMode';
import { ExpoExhibitor } from './expoExhibitor';
import { ExpoContact } from './expoContact';
import { ExhibitorGroup } from './exhibitorGroup';
import { OverviewMode } from './overviewMode';


export interface Expo { 
    id: string;
    shortKey?: string;
    name: string;
    description?: string;
    descriptionLong?: string;
    intro?: string;
    dateStart: Date;
    dateEnd: Date;
    logo?: string;
    logoWide?: string;
    logoSquare?: string;
    background?: string;
    defaultScene?: number;
    active?: boolean;
    isPrivate?: boolean;
    isHidden?: boolean;
    deleted?: boolean;
    owner?: ApplicationUser;
    ownerId?: string;
    contacts?: Array<ExpoContact>;
    exhibitors?: Array<ExpoExhibitor>;
    categories?: Array<Category>;
    chatActive?: boolean;
    schedulingActive?: boolean;
    worldActive?: boolean;
    googleAnalyticsId?: string;
    matomoId?: string;
    externalMatomoServer?: string;
    externalMatomoId?: string;
    promoted?: boolean;
    domain?: string;
    showHall?: boolean;
    accessMode?: AccessMode;
    defaultLanguage?: string;
    sizes?: string;
    displayMax?: number;
    linkMax?: number;
    infoSlideMax?: number;
    textChatEnabled?: boolean;
    videoChatEnabled?: boolean;
    calendarEnabled?: boolean;
    stageEnabled?: boolean;
    stageCount?: number;
    boothCount?: number;
    isPreview?: boolean;
    overviewMode?: OverviewMode;
    stages?: Array<Stage>;
    timeZone?: string;
    introType?: InventoryItemType;
    introSource?: string;
    videoChatMode?: VideoChatMode;
    vodLibraryType?: InventoryItemType;
    vodLibraryId?: string;
    randomChatNames?: boolean;
    sidebarMode?: SidebarMode;
    exhibitorGroups?: Array<ExhibitorGroup>;
    groupingEnabled?: boolean;
    groupingHideUngroupedLanding?: boolean;
    groupingHideUngroupedSidebar?: boolean;
    exhibitorInfoVisibility?: boolean;
    liveStreamAvailable?: boolean;
    liveStreamEnabled?: boolean;
    showStagesOnLanding?: boolean;
    expoDesign?: string;
    tagsAvailable?: boolean;
    tagsEnabled?: boolean;
    tagsExhibitorEditable?: boolean;
    tags?: string;
    catalogEnabled?: boolean;
    boothPresentationEnabled?: boolean;
    surveyEnabled?: boolean;
    zoomRequiresLogin?: boolean;
    externalLinksDirectOpen?: boolean;
    mediaLinksSkipZoom?: boolean;
    rowVersion?: number;
    uploadTs?: Date;
}

/**
 * Namespace for property- and property-value-enumerations of Expo.
 */
export namespace Expo {
    /**
     * All properties of Expo.
     */
    export enum Properties {
        id = 'id',
        shortKey = 'shortKey',
        name = 'name',
        description = 'description',
        descriptionLong = 'descriptionLong',
        intro = 'intro',
        dateStart = 'dateStart',
        dateEnd = 'dateEnd',
        logo = 'logo',
        logoWide = 'logoWide',
        logoSquare = 'logoSquare',
        background = 'background',
        defaultScene = 'defaultScene',
        active = 'active',
        isPrivate = 'isPrivate',
        isHidden = 'isHidden',
        deleted = 'deleted',
        owner = 'owner',
        ownerId = 'ownerId',
        contacts = 'contacts',
        exhibitors = 'exhibitors',
        categories = 'categories',
        chatActive = 'chatActive',
        schedulingActive = 'schedulingActive',
        worldActive = 'worldActive',
        googleAnalyticsId = 'googleAnalyticsId',
        matomoId = 'matomoId',
        externalMatomoServer = 'externalMatomoServer',
        externalMatomoId = 'externalMatomoId',
        promoted = 'promoted',
        domain = 'domain',
        showHall = 'showHall',
        accessMode = 'accessMode',
        defaultLanguage = 'defaultLanguage',
        sizes = 'sizes',
        displayMax = 'displayMax',
        linkMax = 'linkMax',
        infoSlideMax = 'infoSlideMax',
        textChatEnabled = 'textChatEnabled',
        videoChatEnabled = 'videoChatEnabled',
        calendarEnabled = 'calendarEnabled',
        stageEnabled = 'stageEnabled',
        stageCount = 'stageCount',
        boothCount = 'boothCount',
        isPreview = 'isPreview',
        overviewMode = 'overviewMode',
        stages = 'stages',
        timeZone = 'timeZone',
        introType = 'introType',
        introSource = 'introSource',
        videoChatMode = 'videoChatMode',
        vodLibraryType = 'vodLibraryType',
        vodLibraryId = 'vodLibraryId',
        randomChatNames = 'randomChatNames',
        sidebarMode = 'sidebarMode',
        exhibitorGroups = 'exhibitorGroups',
        groupingEnabled = 'groupingEnabled',
        groupingHideUngroupedLanding = 'groupingHideUngroupedLanding',
        groupingHideUngroupedSidebar = 'groupingHideUngroupedSidebar',
        exhibitorInfoVisibility = 'exhibitorInfoVisibility',
        liveStreamAvailable = 'liveStreamAvailable',
        liveStreamEnabled = 'liveStreamEnabled',
        showStagesOnLanding = 'showStagesOnLanding',
        expoDesign = 'expoDesign',
        tagsAvailable = 'tagsAvailable',
        tagsEnabled = 'tagsEnabled',
        tagsExhibitorEditable = 'tagsExhibitorEditable',
        tags = 'tags',
        catalogEnabled = 'catalogEnabled',
        boothPresentationEnabled = 'boothPresentationEnabled',
        surveyEnabled = 'surveyEnabled',
        zoomRequiresLogin = 'zoomRequiresLogin',
        externalLinksDirectOpen = 'externalLinksDirectOpen',
        mediaLinksSkipZoom = 'mediaLinksSkipZoom',
        rowVersion = 'rowVersion',
        uploadTs = 'uploadTs'
    }

    /**
    * A map of tuples with error name and `ValidatorFn` for each property of Expo.
    */
    export const ModelValidators: {[K in keyof Expo]: [string, ValidatorFn][]} = {
        id: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        shortKey: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(10)],
        ],
        name: [
                ['required', Validators.required],
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        description: [
        ],
        descriptionLong: [
        ],
        intro: [
        ],
        dateStart: [
                ['required', Validators.required],
        ],
        dateEnd: [
                ['required', Validators.required],
        ],
        logo: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        logoWide: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        logoSquare: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        background: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(100)],
        ],
        defaultScene: [
        ],
        active: [
        ],
        isPrivate: [
        ],
        isHidden: [
        ],
        deleted: [
        ],
        owner: [
        ],
        ownerId: [
        ],
        contacts: [
        ],
        exhibitors: [
        ],
        categories: [
        ],
        chatActive: [
        ],
        schedulingActive: [
        ],
        worldActive: [
        ],
        googleAnalyticsId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        matomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        externalMatomoServer: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        externalMatomoId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(50)],
        ],
        promoted: [
        ],
        domain: [
        ],
        showHall: [
        ],
        accessMode: [
        ],
        defaultLanguage: [
        ],
        sizes: [
        ],
        displayMax: [
        ],
        linkMax: [
        ],
        infoSlideMax: [
        ],
        textChatEnabled: [
        ],
        videoChatEnabled: [
        ],
        calendarEnabled: [
        ],
        stageEnabled: [
        ],
        stageCount: [
        ],
        boothCount: [
        ],
        isPreview: [
        ],
        overviewMode: [
        ],
        stages: [
        ],
        timeZone: [
        ],
        introType: [
        ],
        introSource: [
        ],
        videoChatMode: [
        ],
        vodLibraryType: [
        ],
        vodLibraryId: [
                ['minlength', Validators.minLength(0)],
                ['maxlength', Validators.maxLength(200)],
        ],
        randomChatNames: [
        ],
        sidebarMode: [
        ],
        exhibitorGroups: [
        ],
        groupingEnabled: [
        ],
        groupingHideUngroupedLanding: [
        ],
        groupingHideUngroupedSidebar: [
        ],
        exhibitorInfoVisibility: [
        ],
        liveStreamAvailable: [
        ],
        liveStreamEnabled: [
        ],
        showStagesOnLanding: [
        ],
        expoDesign: [
        ],
        tagsAvailable: [
        ],
        tagsEnabled: [
        ],
        tagsExhibitorEditable: [
        ],
        tags: [
        ],
        catalogEnabled: [
        ],
        boothPresentationEnabled: [
        ],
        surveyEnabled: [
        ],
        zoomRequiresLogin: [
        ],
        externalLinksDirectOpen: [
        ],
        mediaLinksSkipZoom: [
        ],
        rowVersion: [
        ],
        uploadTs: [
        ],
    };

    /**
    * The FormControlFactory for Expo.
    */
    export class FormControlFactory extends BaseFormControlFactory<Expo> {

        /**
         * Constructor.
         *
         * @param model An existing model for Expo.
         *              If given, all form-controls of the factory automatically have the value of this model. If this
         *              model is not given, all values are `null`.
         */
        constructor(
          model: Expo = {
            id: null,
            shortKey: null,
            name: null,
            description: null,
            descriptionLong: null,
            intro: null,
            dateStart: null,
            dateEnd: null,
            logo: null,
            logoWide: null,
            logoSquare: null,
            background: null,
            defaultScene: null,
            active: null,
            isPrivate: null,
            isHidden: null,
            deleted: null,
            owner: null,
            ownerId: null,
            contacts: null,
            exhibitors: null,
            categories: null,
            chatActive: null,
            schedulingActive: null,
            worldActive: null,
            googleAnalyticsId: null,
            matomoId: null,
            externalMatomoServer: null,
            externalMatomoId: null,
            promoted: null,
            domain: null,
            showHall: null,
            accessMode: null,
            defaultLanguage: null,
            sizes: null,
            displayMax: null,
            linkMax: null,
            infoSlideMax: null,
            textChatEnabled: null,
            videoChatEnabled: null,
            calendarEnabled: null,
            stageEnabled: null,
            stageCount: null,
            boothCount: null,
            isPreview: null,
            overviewMode: null,
            stages: null,
            timeZone: null,
            introType: null,
            introSource: null,
            videoChatMode: null,
            vodLibraryType: null,
            vodLibraryId: null,
            randomChatNames: null,
            sidebarMode: null,
            exhibitorGroups: null,
            groupingEnabled: null,
            groupingHideUngroupedLanding: null,
            groupingHideUngroupedSidebar: null,
            exhibitorInfoVisibility: null,
            liveStreamAvailable: null,
            liveStreamEnabled: null,
            showStagesOnLanding: null,
            expoDesign: null,
            tagsAvailable: null,
            tagsEnabled: null,
            tagsExhibitorEditable: null,
            tags: null,
            catalogEnabled: null,
            boothPresentationEnabled: null,
            surveyEnabled: null,
            zoomRequiresLogin: null,
            externalLinksDirectOpen: null,
            mediaLinksSkipZoom: null,
            rowVersion: null,
            uploadTs: null,
          }
        ) {
            super(model, Expo.ModelValidators);
        }
    }

}


